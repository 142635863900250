import React from "react";
import Header from './../Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col, Container} from 'react-bootstrap';
import classes from './Contacts.module.css';
import ModalUs from '../../components/Contacts';
import Info from '../../components/ContactsInfo';
import Map from '../../components/AddressMap';
import Meta from "../../components/Meta";

function Сontacts() {
    return (
        <>
            <Meta/>
            <Header/>
            <div className={classes.bg}>
            <Container>
                <Row>
                    <Col md={6} sm={12}>
                        <h4 className={classes.heading}>Наши контакты</h4>
                        <ModalUs/>
                    </Col>
                    <Col md={6} sm={12}>
                        <Info/>
                    </Col>
                    <Col md={12} sm={12}>
                        <Map/>
                    </Col>
                </Row>
            </Container>
        </div>
        </>

    );
}

export default Сontacts;