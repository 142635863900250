import React from 'react';
import './App.css';
import Rent from './Pages/Rent/Rent';
import Iframe2 from './Pages/Iframe';
import {
    BrowserRouter as Router,
    Route
} from "react-router-dom";
import About from "./Pages/About/About";
import Сontacts from "./Pages/Contacts/Сontacts";

function App() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    return (
      <Router>
        <div className="App">
          <Route exact={true} path="/" component={isMobile ? About : Iframe2} />
          <Route exact={true} path="/business_center" component={About} />
          <Route path="/rent" component={Rent} />
          <Route path="/contacts" component={Сontacts} />
          <Route exact={true} path="/presentation" component={Iframe2} />
        </div>
      </Router>
    );
  }

  export default App;