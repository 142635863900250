import React from "react";
import Header from './../Header';
import classes from './About.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Row, Col, Container} from 'react-bootstrap';
import parking from '../../images/parking.svg';
import security from '../../images/security.svg';
import name from '../../images/name.svg';
import repairs from '../../images/repairs.svg';
import weather from '../../images/weather.svg';
import area from '../../images/area.svg';
import pointer from '../../images/pointer.svg';
import ModalUs from '../../components/Contacts';
import Info from '../../components/ContactsInfo';
import Map from '../../components/AddressMap';
import Meta from "../../components/Meta";


function About() {

    return (
        <>
            <Meta/>
            <Header/>
            <div className={classes.hedader}>
                <Container>
                    <Row>
                        <Col lg={7} md={12} sm={12}>
                            <h2 className={classes.heading}>Бизнес-центр «7А» класса В+ предлагает аренду офисных помещений
                                площадью от 12 до 206 м<sup>2</sup></h2>
                            <p className={classes.subtitle}>Стоимость аренды от <span className={classes.span}>21 рубля за м<sup>2</sup></span>
                            </p>

                            <ModalUs/>

                            <a href="/presentation" className={classes.arrow} onClick={(e) => {
                                e.preventDefault();
                                window.location.href = '/presentation';
                            }}><img className={classes.pointer} src={pointer} alt="arrow"/>Посмотреть презентацию</a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className={classes.services}>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <h2 className={classes.title}>К вашим услугам</h2>
                            <p className={classes.lead}>Современный бизнес-центр расположен по адресу ул.&#160;Чеботарева&#160;7а,
                                всего в 4 минутах ходьбы от станции метро «Тракторный завод».</p>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={area} alt="area"></img>
                                    <p className={classes.text}>Помещения различной площади от 12 до 206 кв. м</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={security} alt="security"></img>
                                    <p className={classes.text}>Круглосуточная охрана и видеонаблюдение</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={name} alt="name"></img>
                                    <p className={classes.text}>Карточная система учета посетителей</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={repairs} alt="repairs"></img>
                                    <p className={classes.text}>Современный ремонт</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={weather} alt="weather"></img>
                                    <p className={classes.text}>Системы кондиционирования</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12}>
                            <div className={classes.card}>
                                <div className={classes.service}>
                                    <img src={parking} alt="parking"></img>
                                    <p className={classes.text}>Вместительная парковка на 76 автомобилей</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
          <Row>
            <Col md={6} sm={12}  className={classes.mapMargin}>
              <Map />
            </Col>
            <Col md={6} sm={12} className={classes.bg}>
              <Info />
            </Col>
          </Row>
        </>
    );
}


export default About;