import React, {Component} from 'react';
import classes from "../Pages/Contacts/Contacts.module.css";

class ContactsInfo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            phone : null,
            mail : null,
        }
    }

    componentDidMount() {
        const server = process.env.REACT_APP_API_URL;
        const requestOptions = {
            method: 'GET',
        };

        fetch(server + '/api/get-contact-info', requestOptions)
            .then(res => res.json())
            .then((result) => {
                    this.setState({
                        phone: result.data.phone,
                        mail: result.data.email,
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }).catch(alert);
    }

    render() {
        return (
            <div className={classes.content}>
                <div className={classes.contacts}>
                    <div className={classes.address}>
                        <p>г. Минск, ул. Чеботарева 7а</p>
                    </div>
                    <div className={classes.subway}>
                        <p>станция метро «Тракторный завод»</p>
                    </div>
                    <div className={classes.phone}>
                        <p><a href={'tel:+' + this.state.phone}>{this.state.phone}</a></p>
                    </div>
                    <div className={classes.mail}>
                        <p><a href={'mailto:' + this.state.mail}>{this.state.mail}</a></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactsInfo;