import React from "react";
import classes from './Rent.module.css';
import Map from "../../components/AddressMap";
import Info from "../../components/ContactsInfo";
import Header from './../Header';
import {Row, Col, Tab, Tabs, Container, Carousel} from 'react-bootstrap';
import Meta from "../../components/Meta";


class Rent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: this.activeTab || 1,
            floor1: [],
            floor2: [],
            floor3: [],
            floor4: [],
        };
        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(selectedTab) {
        this.setState({
            activeTab: selectedTab,
        });
    }

    componentDidMount() {
        const server = process.env.REACT_APP_API_URL;
        const requestOptions = {
            method: 'GET',
        };

        fetch(server + '/api/get-images', requestOptions)
            .then(res => res.json())
            .then((result) => {
                    this.setState({
                        floor1: result.data[0].floor1,
                        floor2: result.data[1].floor2,
                        floor3: result.data[2].floor3,
                        floor4: result.data[3].floor4,
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }).catch(alert);
    }

    render() {
        return (
            <>
                <Meta/>
                <Header/>
                <div className={classes.rent}>
                    <Container fluid>
                        <Row>
                            <Col sm={12} className={classes.nopadding}>
                                <h2 className={classes.heading}>Аренда</h2>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div>
                    <Container>
                        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" activeKey={this.state.activeTab}
                              onSelect={this.handleSelect} className={classes.tabs}>
                            <Tab eventKey={1} title="1-й этаж" className={classes.tab}>
                                <Carousel className={classes.items}>
                                    {this.state.floor1.map((image, index) =>
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image.path} alt={image.name}/>
                                            <div className={classes.caption}>{image.description}</div>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </Tab>
                            <Tab eventKey={2} title="2-й этаж" className={classes.tab}>
                                <Carousel className={classes.items}>
                                    {this.state.floor2.map((image, index) =>
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image.path} alt={image.name}/>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </Tab>
                            <Tab eventKey={3} title="3-й этаж" className={classes.tab}>
                                <Carousel className={classes.items}>
                                    {this.state.floor3.map((image, index) =>
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image.path} alt={image.name}/>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </Tab>
                            <Tab eventKey={4} title="4-й этаж" className={classes.tab}>
                                <Carousel className={classes.items}>
                                    {this.state.floor4.map((image, index) =>
                                        <Carousel.Item key={index}>
                                            <img className="d-block w-100" src={image.path} alt={image.name}/>
                                        </Carousel.Item>
                                    )}
                                </Carousel>
                            </Tab>
                        </Tabs>
                    </Container>
                </div>
                <Row>
                    <Col md={6} sm={12} className={classes.mapMargin}>
                        <Map/>
                    </Col>
                    <Col md={6} sm={12} className={classes.bg}>
                        <Info/>
                    </Col>
                </Row>
                {/*<Footer/>*/}
            </>
        );
    }
}

export default Rent;