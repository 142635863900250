import React, {Component} from 'react';
import classes from "../Pages/Contacts/Contacts.module.css";
import {Button, Form, Modal} from "react-bootstrap";

class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            name : '',
            phone : '',
            question : '',
            show : false,
        };
        this.onChange = this.onChange.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.sendMail = this.sendMail.bind(this);
    }

    handleModal() {
        if (this.state.show) this.setState({ show : false });
        else this.setState({ show : true });
    }
    onChange(e) {
        this.setState({
            [e.target.name] : e.target.value,
        });
    }

    sendMail() {
        const {name, phone, question} = this.state;
        const server = process.env.REACT_APP_API_URL;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                name: name,
                phone: phone,
                question: question,
            })
        };

        fetch(server + '/api/send-mail', requestOptions)
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (data.success) {
                    alert(data.message);
                    localStorage.setItem('send-token', 'Anti-Spam');
                }
            }).catch(alert);
        setTimeout(() => {
            this.setState({show: false});
        }, 1300);
    }

    render() {
        if (localStorage.getItem('send-token') !== null)
            return (
                <div className={classes.contact}>
                    <h5 className={classes.heading}>Вы уже отправляли письмо.</h5>
                </div>
            );
        else return (
            <div className={classes.contact}>
                <a className={classes.btn} variant="primary" onClick={this.handleModal}>Связаться с нами</a>

                <Modal show={this.state.show} onHide={this.handleModal} centered>
                    <Modal.Header closeButton className={classes.modalHeader}>
                        <Modal.Title className={classes.modalTitle}>Контакт</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={classes.modalBody}>
                        <Form>
                            <Form.Group controlId="formBasicName" className={classes.modalGroup}>
                                <Form.Label className={classes.modalLabel}>Имя</Form.Label>
                                <Form.Control className={classes.modalControl} type="name" name='name' onChange={this.onChange}
                                              placeholder=""/>
                            </Form.Group>

                            <Form.Group controlId="formBasicPhone">
                                <Form.Label className={classes.modalLabel}>Телефон</Form.Label>
                                <Form.Control className={classes.modalControl} type="phone" name='phone' onChange={this.onChange}
                                              placeholder=""/>
                            </Form.Group>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label className={classes.modalLabel}>Сообщение</Form.Label>
                                <Form.Control className={classes.modalControl} as="textarea" name='question' onChange={this.onChange}
                                              rows="3"/>
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={this.sendMail} className={classes.modalBtn}>
                                Отправить
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Contacts;