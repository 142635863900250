import React from 'react';
import classes from './Header.module.css';
import video from './../images/video.mp4';
import arrow from '../images/arrow.svg';
import Meta from "../components/Meta";


class Iframe2 extends React.Component {
    onclick () {
      window.location.assign('/rent');
    }

    render() {
      return (
          <>
              <Meta/>
        <div>
            <video className={classes.video} autoPlay loop muted><source src={video} type='video/mp4'/>Your browser does not support the video tag.</video>
            <a href="/rent" className={classes.btn} onClick={(e) => this.onclick(e)}><img className={classes.arrow} src={arrow} alt="arrow" />Подробнее</a>
        </div>
              </>
      );
    }
}
  
export default Iframe2;