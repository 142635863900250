import React, {Component} from 'react';
import classes from "../Pages/Contacts/Contacts.module.css";
import Iframe from "react-iframe";

class AddressMap extends Component {
    render() {
        return (
            <Iframe className={classes.iframe}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2351.3754746714644!2d27.607779416086483!3d53.88952998009643!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbce39a92d4d7d%3A0xe04e1f08cd524103!2z0YPQuy4g0KfQtdCx0L7RgtCw0YDQtdCy0LAgN0EsINCc0LjQvdGB0Lo!5e0!3m2!1sru!2sby!4v1601886127305!5m2!1sru!2sby" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"
                    width="100%" height="450"/>
        );
    }
}

export default AddressMap;