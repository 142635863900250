import React, {Component} from 'react';
import MetaTags from 'react-meta-tags';

class Meta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            metaTags: []
        }
    }

    componentDidMount() {
        const server = process.env.REACT_APP_API_URL;
        const alias = window.location.pathname.substring(1) ? window.location.pathname.substring(1) : 'default';
        const requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({alias : alias})
        };
        console.log(requestOptions)

        fetch(server + '/api/get-meta', requestOptions)
            .then(res => res.json())
            .then((result) => {
                console.log(result)
                    this.setState({
                        metaTags: result.data,
                    });
                },
                (error) => {
                    this.setState({
                        error
                    });
                }).catch(alert);
    }

    render() {
        return (
            <MetaTags>

                {this.state.metaTags.map((meta, index) =>
                    meta.name === 'title' ? <title key={index}>{meta.content}</title>
                        : meta.property ? <meta key={index} name={meta.name} property={meta.property} content={meta.content}/>
                        : <meta key={index} name={meta.name} content={meta.content}/>
                )}
            </MetaTags>
        );
    }
}

export default Meta;